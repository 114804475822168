@import 'global/fonts';
@import 'global/variables';

body,
#root,
main {
  margin: 0;
  width: 100%;
  height: 100%;
}
