//TODO: load greycliff

@font-face {
  font-family: 'Greycliff';
  src: url('../../assets/fonts/Greycliff-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: "Greycliff";
//  src: url("../../assets/fonts/Greycliff-Light.ttf") format("truetype");
//  font-weight: normal;
//  font-style: normal;
//}
